<template>
  <BaseModal
    :activeModal="show"
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="save"
    :close="false"
    :bgClose="false"
    size="md"
  >
    <template #header>
      Edycja zlecenia
    </template>
    <template #default>
      <div v-if="isBusy" class="w-full p-4 h-full text-center">
        <font-awesome-icon class="text-6xl" icon="spinner" pulse/>
      </div>
      <div v-else class="flex flex-wrap">
        <div class="mb-4 w-1/2 px-1">
          <label>data zlecenia</label>
          <input :class="{ 'border-red-300': errors.name !== undefined }" v-model="form.order_date" type="date" :disabled="!allowedUpdateFields.includes('order_date')">
          <p v-if="errors.order_date !== undefined" class="text-red-500 text-sm">{{ errors.order_date }}</p>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>status</label>
          <select v-if="allowedUpdateFields.includes('status_id')" v-model="form.status_id">
            <option v-for="status in allowedStatuses" :key="status.id" :value="status.id">{{ status.name }}</option>
          </select>
          <input v-else :value="order.status.name" type="text" disabled>
          <p v-if="errors.status_id !== undefined" class="text-red-500 text-sm">{{ errors.status_id }}</p>
        </div>
        <div class="mb-4 w-1/2 px-1">
          <label>data oględzin</label>
          <input :class="{ 'border-red-300': errors.inspection_date !== undefined }" v-model="form.inspection_date" type="date" :disabled="!allowedUpdateFields.includes('inspection_date')">
          <p v-if="errors.inspection_date !== undefined" class="text-red-500 text-sm">{{ errors.inspection_date }}</p>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>typ zlecenia</label>
          <select v-if="allowedUpdateFields.includes('type_id')" v-model="form.type_id">
            <option v-for="type in types" :key="type.id" :value="type.id">{{ type.name }}</option>
          </select>
          <input v-else :value="order.type.name" type="text" disabled>
          <p v-if="errors.type_id !== undefined" class="text-red-500 text-sm">{{ errors.type_id }}</p>
        </div>
        <div class="mb-4 w-1/2 px-1">
          <label>nr szkody TU</label>
          <input :class="{ 'border-red-300': errors.claim_no !== undefined }" v-model="form.claim_no" type="text" :disabled="!allowedUpdateFields.includes('claim_no')">
          <p v-if="errors.claim_no !== undefined" class="text-red-500 text-sm">{{ errors.claim_no }}</p>
        </div>
        <div class="mb-4 w-full md:w-1/2 px-1">
          <label>zleceniodawca</label>
          <select v-if="allowedUpdateFields.includes('contractor_id')" v-model="form.contractor_id">
            <option v-for="contractor in contractors" :key="contractor.id" :value="contractor.id">{{ contractor.shortname }}</option>
          </select>
          <input v-else :value="order.contractor.company.shortname" type="text" disabled>
          <p v-if="errors.contractor_id !== undefined" class="text-red-500 text-sm">{{ errors.contractor_id }}</p>
        </div>
        <div v-if="allowedUpdateFields.includes('comments')" class="mb-4 w-full px-1">
          <label>uwagi do szkody <span class="text-xs font-hairline">(tylko administratorzy)</span></label>
          <input :class="{ 'border-red-300': errors.comments !== undefined }" v-model="form.comments" type="text">
          <p v-if="errors.comments !== undefined" class="text-red-500 text-sm">{{ errors.comments }}</p>
        </div>
        <div class="w-full uppercase text-gray-600">
          miejsce oględzin i kontakt
          <hr>
        </div>
        <div class="mb-4 w-1/3 px-1">
          <label>telefon ze zgłoszenia</label>
          <input :class="{ 'border-red-300': errors.phone !== undefined }" v-model="form.phone" type="text" :disabled="!allowedUpdateFields.includes('phone')">
          <p v-if="errors.phone !== undefined" class="text-red-500 text-sm">{{ errors.phone }}</p>
        </div>
        <div class="mb-4 w-2/3 px-1">
          <label>email ze zgłoszenia</label>
          <input :class="{ 'border-red-300': errors.email !== undefined }" v-model="form.email" type="email" :disabled="!allowedUpdateFields.includes('email')">
          <p v-if="errors.email !== undefined" class="text-red-500 text-sm">{{ errors.email }}</p>
        </div>
        <div class="mb-4 w-2/3 px-1">
          <label>ulica</label>
          <input :class="{ 'border-red-300': errors.street !== undefined }" v-model="form.street" type="text" :disabled="!allowedUpdateFields.includes('street')">
          <p v-if="errors.street !== undefined" class="text-red-500 text-sm">{{ errors.street }}</p>
        </div>
        <div class="mb-4 w-1/3 px-1">
          <label>nr domu/mieszkania</label>
          <input :class="{ 'border-red-300': errors.house_apt_no !== undefined }" v-model="form.house_apt_no" type="text" :disabled="!allowedUpdateFields.includes('house_apt_no')">
          <p v-if="errors.house_apt_no !== undefined" class="text-red-500 text-sm">{{ errors.house_apt_no }}</p>
        </div>
        <div class="mb-4 w-1/3 md:w-1/6 px-1">
          <label>kod poczt.</label>
          <Autocomplete
            resource="postal-codes"
            :inputClass="errors.postal_code !== undefined ? 'border-red-300' : ''"
            :disabledInput="!allowedUpdateFields.includes('postal_code')"
            v-model="form.postal_code"
            @selected="setCity"
          />
          <p v-if="errors.postal_code !== undefined" class="text-red-500 text-sm">{{ errors.postal_code }}</p>
        </div>
        <div class="mb-4 w-2/3 md:w-1/2 px-1">
          <label>miasto</label>
          <Autocomplete
            resource="cities"
            :inputClass="errors.city !== undefined ? 'border-red-300' : ''"
            :disabledInput="!allowedUpdateFields.includes('city')"
            v-model="form.city"
          />
          <p v-if="errors.city !== undefined" class="text-red-500 text-sm">{{ errors.city }}</p>
        </div>
        <div class="mb-4 w-full md:w-1/3 px-1">
          <label>region</label>
          <select v-if="allowedUpdateFields.includes('region_id')" v-model="form.region_id" :class="{ 'border-red-300': errors.region_id !== undefined }">
            <option value="" disabled hidden>Wybierz</option>
            <option v-for="region in regions" :key="region.id" :value="region.id">{{ region.name }}</option>
          </select>
          <input v-else :value="order.region ? order.region.name : 'nie podano'" type="text" disabled>
          <p v-if="errors.region_id !== undefined" class="text-red-500 text-sm">{{ errors.region_id }}</p>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from 'vuex';
import OrderService from '@/services/OrderService';
import { errorHandler } from '../mixins/errorHandler';
import Autocomplete from './Autocomplete.vue';

export default {
  name: 'OrderEdit',
  mixins: [errorHandler],
  components: { Autocomplete },
  props: {
    order: {
      type: Object,
      required: true,
    },
    roles: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        order_date: '',
        status_id: '',
        inspection_date: '',
        type_id: '',
        claim_no: '',
        contractor_id: '',
        phone: '',
        email: '',
        street: '',
        house_apt_no: '',
        postal_code: '',
        city: '',
        region_id: '',
        comments: '',
      },
      errors: {},
      show: false,
      isBusy: false,
    };
  },
  computed: {
    ...mapState('orders', [
      'statuses',
      'types',
      'contractors',
    ]),
    ...mapState('regions', ['regions']),
    allowedUpdateFields() {
      const fields = [];
      if (this.$store.state.user.abilities.includes('can_everything')) {
        Object.keys(this.form).forEach(x => {
          fields.push(x);
        });
      }
      if (this.$store.state.user.abilities.includes('registrar')) {
        const denied = ['inspection_date', 'valuation_date', 'status_id'];
        Object.keys(this.form).forEach(x => {
          if (!denied.includes(x)) {
            fields.push(x);
          }
        });
      }
      if (this.roles.is_inspector) {
        fields.push('inspection_date');
        if (['10', '14', '16', 10, 14, 16].includes(this.order.status_id)) {
          fields.push('status_id');
        }
        // fields = fields.concat(['status_id', 'inspection_date']);
      }
      if (this.roles.is_valuator) {
        if (['20', '22', '24', 20, 22, 24].includes(this.order.status_id)) {
          fields.push('status_id');
        }
        // fields = fields.concat(['status_id']);
      }
      return fields.filter((val, index, self) => self.indexOf(val) === index);
    },
    allowedStatuses() {
      if (this.$store.state.user.abilities.includes('can_everything')) {
        return this.statuses;
      }
      let statuses = [];
      if (this.roles.is_inspector) {
        statuses = statuses.concat(this.statuses.filter(x => ['10', '14', '16', 10, 14, 16].includes(x.id)));
      }
      if (this.roles.is_valuator) {
        statuses = statuses.concat(this.statuses.filter(x => ['20', '22', '24', 20, 22, 24].includes(x.id)));
      }
      return statuses;
    },
  },
  mounted() {
    this.$store.dispatch('orders/getStatuses');
    if (this.types.length === 0) {
      this.$store.dispatch('orders/getTypes');
    }
    this.$store.dispatch('orders/getContractors');
    if (this.regions.length === 0) {
      this.$store.dispatch('regions/get');
    }
    if (!this.$store.state.user.abilities.includes('can_everything')) {
      delete this.form.comments;
    }
    this.setForm();
    this.show = true;
  },
  methods: {
    save() {
      this.isBusy = true;
      this.errors = {};
      const fields = {};
      this.allowedUpdateFields.forEach(x => {
        fields[x] = this.form[x];
      });
      OrderService.updateOrder(this.order.id, fields)
        .then(response => {
          this.isBusy = false;
          this.$emit('changed', response.data.result);
          this.show = false;
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: 'Zmiany zostały zapisane...',
          });
        })
        .catch(error => {
          this.errors = this.resolveError(error);
          this.isBusy = false;
        });
    },
    setCity(payload) {
      this.form.city = payload.city;
    },
    setForm() {
      Object.keys(this.form).forEach(field => {
        this.form[field] = this.order[field] !== null ? this.order[field].toString() : '';
      });
    },
  },
};
</script>
