<template>
  <div class="card relative" ref="container">
    <div class="card-header">
      <span class="card-header-title">Rozliczenie zlecenia</span>
      <div class="relative w-auto">
        <span v-show="!hasRequired && !loading" @click="showNoteAdd = true" class="text-red-500 cursor-pointer mr-2" title="Uzupełnij pozycje wynagrodzeń">
          <font-awesome-icon icon="exclamation-triangle" />
        </span>
        <span v-show="order.settlements_checked == 1 && !loading" @click="showNoteAdd = true" class="text-green-600 cursor-pointer mr-2" title="Rozliczenie sprawdzone i zatwierdzone">
          <font-awesome-icon icon="check" />
        </span>
      </div>
    </div>
    <div class="card-content">
      <!-- Spinner Loading -->
      <div v-if="loading" class="absolute top-0 left-0 w-full h-full">
        <div class="w-full h-full bg-gray-400 opacity-75"></div>
        <div class="absolute top-0 left-0 flex flex-col w-full h-full items-center justify-center">
          <div class="mb-2">
            ładuję dane
          </div>
          <font-awesome-icon class="mr-3" icon="spinner" size="2x" spin />
        </div>
      </div>
      <!-- END Spinner Loading -->
      <div class="flex flex-wrap mt-1 border border-gray-400 rounded-sm">
        <div class="flex px-2 py-1 justify-between w-full sm:w-1/2">
          <div class="font-semibold">Przychód</div>
          <div v-if="!showIncomeEdit" class="flex w-full max-w-sm ml-3">
            <div class="field-data">{{ order.income | moneyPL }}</div>
            <button
              v-show="!showIncomeEdit"
              @click="editIncome"
              class="ml-1 text-blue-300 hover:text-blue-500"
              title="Edytuj przychód"
            ><font-awesome-icon icon="edit" /></button>
          </div>
          <div v-else class="flex w-full max-w-sm ml-3">
            <input
              v-focus
              @keyup.enter="saveIncome"
              @keyup.esc="cancelEditIncome"
              class="py-0 text-right"
              :class="{ 'border-red-300': errors.income !== undefined }"
              v-model="form.income"
              type="text"
            >
            <button
              v-show="showIncomeEdit && !incomeIsBusy"
              @click="saveIncome"
              class="ml-1 text-yellow-500 hover:text-yellow-700"
              title="Zapisz"
            ><font-awesome-icon icon="save" /></button>
            <button class="ml-1" disabled><font-awesome-icon v-show="incomeIsBusy" icon="spinner" spin /></button>
            <button
              v-show="showIncomeEdit"
              @click="cancelEditIncome"
              class="ml-1 text-gray-400 hover:text-gray-600"
              title="Anuluj"
              :disabled="incomeIsBusy"
            ><font-awesome-icon icon="times" /></button>
          </div>
        </div>
        <div class="flex px-2 py-1 justify-between w-full sm:w-1/2">
          <div class="font-semibold">Dochód</div>
          <div class="w-full max-w-sm ml-3">
            <div
              class="field-data"
              :class="{'text-blue-500': profit > 0, 'text-red-500': profit < 0, 'font-semibold': profit != 0}"
            >{{ profit | moneyPL }}</div>
          </div>
        </div>
      </div>
      <div class="px-2 py-1 mt-1 border border-gray-400 rounded-sm">
        <div class=" mb-1 w-full border-b font-semibold">Wynagrodzenia</div>
        <div v-for="employee in employeesSalaries" :key="employee.id" class="w-full mb-1">
          <div class="flex w-full">
            <div class="px-1 bg-gray-300">{{employee.name}}</div>
            <button @click="addSalary(employee.id)" class="px-2 text-blue-500 hover:text-blue-700" title="Dodaj pozycję wynagrodzenia">
              <font-awesome-icon icon="plus" />
            </button>
          </div>
          <div v-for="salary in employee.salaries" :key="salary.id" class="flex flex-wrap justify-between ml-5 border-b border-dashed">
            <div class="flex">
              <button
                @click="editSalary(salary)"
                class="mr-1"
                :class="!salary.payment_date ? 'text-blue-300 hover:text-blue-500': 'text-gray-400'"
                :title="salary.payment_date ? 'Wynagrodzenie wypłacone - edycja niemożliwa' : 'Edytuj'"
                :disabled="salary.payment_date"
              ><font-awesome-icon icon="edit" /></button>
              <button
                @click="delHandler(salary.id)"
                class="mr-1"
                :class="!salary.payment_date ? 'text-red-300 hover:text-red-500': 'text-gray-400'"
                :title="salary.payment_date ? 'Wynagrodzenie wypłacone - akcja niemożliwa' : 'Usuń'"
                :disabled="salary.payment_date"
              ><font-awesome-icon icon="trash-alt" /></button>
              <div>
                {{salary.salary_type.name}}
              </div>
              <div v-show="(salary.salary_type_id === '20' || salary.salary_type_id === 20) && salary.description">
                <font-awesome-icon class="mx-2 text-blue-300 cursor-pointer" icon="question-circle" :title="salary.description" />
              </div>
            </div>
            <div>
              <span v-if="salary.payment_date" class="text-green-400 cursor-pointer" :title="salary.payment_date">
                <font-awesome-icon icon="check" />
              </span>
              <span v-else class="text-gray-300" title="do wypłaty">
                <font-awesome-icon icon="hourglass-start" />
              </span>
              <span class="pl-5 font-mono">{{salary.amount | moneyPL}}</span>
            </div>
          </div>
          <div v-if="employee.salaries.length > 1" class="w-full text-right font-semibold">
            Suma <span class="text-xs font-normal"><em>(pracownik)</em></span>: <span class="font-mono">{{employee.salarySum | moneyPL}}</span>
          </div>
        </div>
      </div>
    </div> <!-- end card-content -->
    <OrderSalaryAddEdit
      v-if="showSalaryAddEdit"
      @close="cancelAddEditSalary"
      @added="salaryAdded"
      @updated="salaryUpdated"
      @settlementsCheckedChanged="settlementsCheckedChanged"
      :salary="salaryToEdit"
      :order="order"
      :employeeId="employeeToAddSalary"
    />
  </div> <!-- card -->
</template>

<script>
import moment from 'moment';
import OrderSalaryAddEdit from './OrderSalaryAddEdit.vue';
import OrderService from '../services/OrderService';
import SalaryService from '../services/SalaryService';
import { errorHandler } from '../mixins/errorHandler';
import { confirm } from '../mixins/confirm';

export default {
  name: 'OrderSalaries',
  components: {
    OrderSalaryAddEdit,
  },
  mixins: [errorHandler, confirm],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      salaries: [],
      showSalaryAddEdit: false,
      employeeToAddSalary: '',
      salaryToEdit: {},
      showIncomeEdit: false,
      incomeIsBusy: false,
      form: {
        income: '',
      },
      now: moment().format('YYYY-MM-DD'),
      errors: {},
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    employeesSalaries() {
      const employees = [];
      const employeesUnique = [];
      const employeesSalaries = [];
      this.order.supervisions.forEach(el => { employees.push(el); });
      this.order.inspectors.forEach(el => { employees.push(el); });
      this.order.valuators.forEach(el => { employees.push(el); });
      const uniqueIds = employees.map(el => el.id).filter((element, index, self) => self.indexOf(element) === index);
      uniqueIds.forEach(id => {
        employeesUnique.push(employees.find(obj => obj.id === id));
      });
      employeesUnique.forEach(obj => {
        const salaries = this.salaries.filter(salarie => Number(salarie.employee_id) === Number(obj.id));
        const salarySum = salaries.reduce((prev, next) => ({ amount: Number(prev.amount) + Number(next.amount) }), { amount: 0 }).amount;
        employeesSalaries.push({
          id: obj.id,
          name: `${obj.person.first_name} ${obj.person.surname}`,
          salaries,
          salarySum,
        });
      });
      return employeesSalaries;
    },
    profit() {
      const cost = this.salaries.reduce((prev, next) => ({ amount: Number(prev.amount) + Number(next.amount) }), { amount: 0 }).amount;
      return Number((Number(this.order.income) - cost).toFixed(2));
    },
    hasRequired() {
      let validation = true;
      this.order.inspectors.forEach(el => {
        if (!this.salaries.find(obj => Number(obj.employee_id) === Number(el.id))) {
          validation = false;
        }
      });
      this.order.valuators.forEach(el => {
        if (!this.salaries.find(obj => Number(obj.employee_id) === Number(el.id))) {
          validation = false;
        }
      });
      return validation;
    },
  },
  mounted() {
    SalaryService.getOrderSalaries(this.order.id)
      .then(response => {
        this.salaries = response.data.result;
        this.loading = false;
      })
      .catch(error => {
        this.resolveError(error);
        this.loading = false;
      });
  },
  methods: {
    editIncome() {
      if (this.order.income !== null && this.order.income !== '') {
        this.form.income = this.order.income.replace('.', ',');
      }
      this.showIncomeEdit = true;
    },
    cancelEditIncome() {
      this.form.income = '';
      this.showIncomeEdit = false;
    },
    saveIncome() {
      this.incomeIsBusy = true;
      const formIncomeCache = this.form.income;
      this.form.income = this.form.income.replace(',', '.');
      OrderService.updateOrder(this.order.id, this.form)
        .then(response => {
          this.$emit('incomeUpdated', response.data.result.income);
          this.showIncomeEdit = false;
          this.incomeIsBusy = false;
        })
        .catch(error => {
          this.errors = this.resolveError(error);
          this.form.income = formIncomeCache;
          this.incomeIsBusy = false;
        });
      return true;
    },
    settlementsCheckedChanged() {
      OrderService.updateOrder(this.order.id, { settlements_checked: 0 })
        .then(() => {
          this.$emit('settlementsCheckedChanged');
        })
        .catch(error => {
          this.resolveError(error);
        });
    },
    cancelAddEditSalary() {
      this.salaryToEdit = {};
      this.showSalaryAddEdit = false;
    },
    editSalary(salary) {
      this.salaryToEdit = salary;
      this.showSalaryAddEdit = true;
    },
    salaryUpdated(updatedSalary) {
      const salary = this.salaries.find(obj => Number(obj.id) === Number(updatedSalary.id));
      Object.assign(salary, updatedSalary);
      this.salaryToEdit = {};
    },
    addSalary(employeeId) {
      this.employeeToAddSalary = employeeId;
      this.showSalaryAddEdit = true;
    },
    salaryAdded(salary) {
      this.salaries.push(salary);
      this.settlementsCheckedChanged();
    },
    delHandler(id) {
      this.confirm(() => this.delSalary(id), 'Potwierdź usunięcie pozycji');
    },
    delSalary(id) {
      SalaryService.delete(id)
        .then(() => {
          this.settlementsCheckedChanged();
          this.salaries = this.salaries.filter(obj => obj.id !== id);
          this.$store.dispatch('notifications/add', { type: 'success', message: 'Wynagrodzenie usunięte' });
        })
        .catch(error => {
          this.resolveError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .card {
    @apply my-2;
  }
  .card-header {
     @apply flex;
     @apply justify-between;
     @apply px-3;
     @apply py-1;
     @apply border;
     @apply border-b-0;
     @apply border-gray-400;
     @apply bg-gray-100;
     @apply rounded-t-sm;
     &.dark {
       @apply bg-gray-900;
       @apply text-gray-200;
       @apply border-gray-700;
     }
  }
  .card-header-title {
    @apply font-semibold;
  }
  .card-content {
    @apply px-3;
    @apply pt-1;
    @apply pb-3;
    @apply border;
    @apply border-gray-400;
    &.dark {
      @apply bg-gray-800;
      @apply text-gray-200;
      @apply border-gray-700;
    }
  }
  .card-data-section {
    @apply flex;
    @apply flex-wrap;
    @apply -mx-3;
    @apply -my-2;
  }
  .field-data {
      @apply inline-block;
      @apply w-full;
      @apply px-2;
      @apply border;
      @apply bg-gray-200;
      @apply rounded-sm;
      @apply text-right;
    }
</style>
