<template>
  <div>
    <!-- Pobierz ZIP -->
    <div class="flex justify-end w-full">
      <button v-if="userIsContractor && order.status_id == 50" @click="setAsDownloaded" class="px-3 mr-2 text-gray-100 py-1 bg-blue-700 hover:bg-blue-500 rounded-sm" :disabled="!order.notes || order.notes.length === 0">
        Oznacz jako Pobrane
      </button>
      <button @click="downloadCompleteOrder" class="px-3 text-gray-100 py-1 bg-teal-700 hover:bg-teal-500 rounded-sm" :disabled="!order.notes || order.notes.length === 0">
        Pobierz ZIP <font-awesome-icon icon="download" />
      </button>
    </div>
    <!-- cards in 2 columns -->
    <div v-if="isBusy">
      <div class="card">
        <div class="card-header">
          <span class="card-header-title">Ładuję dane...</span>
        </div>
        <div class="card-content">
          <div v-if="isBusy" class="w-full p-4 h-full text-center">
            <font-awesome-icon class="text-6xl" icon="spinner" pulse/>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="main-container">
      <div class="container-column w-full md:w-1/2"> <!-- column left -->
        <div class="card">
          <div class="card-header">
            <span class="card-header-title">Zlecenie nr: {{ order.signature }}</span>
            <span class="card-header-title text-blue-600"><span class="text-gray-500 font-light">status:</span> {{ order.status.name }}</span>
          </div>
          <div class="card-content">

            <div class="w-full flex justify-end"> <!-- ikonki -->
              <font-awesome-icon v-show="canEverything || userIsInspector || userIsValuator || userIsRegistrar" @click="showOrderEdit = true" class="text-gray-500 hover:text-blue-600 cursor-pointer" icon="edit" />
              <font-awesome-icon v-show="canEverything" @click="confirmDeleteOrder" class="ml-1 text-gray-500 hover:text-red-600 cursor-pointer" icon="trash-alt" />
            </div> <!-- end ikonki -->

            <div class="card-data-section"> <!-- data section -->
              <div class="column w-1/2">
                <span class="title">data zlecenia</span>
                <span class="field-data">{{ order.order_date }}</span>
              </div>
              <div class="column w-1/2">
                <span class="title">status</span>
                <span class="field-data">{{ order.status.name }}</span>
              </div>
              <div class="column w-1/2">
                <span class="title">data oględzin</span>
                <span class="field-data" :class="{'border-yellow-500 text-red-700': !order.inspection_date}">{{ order.inspection_date ? order.inspection_date : 'brak' }}</span>
              </div>
              <div class="column w-1/2">
                <span class="title">typ zlecenia</span>
                <span class="field-data">{{ order.type.name }}</span>
              </div>
              <div class="column w-1/2">
                <span class="title">nr szkody TU</span>
                <span class="field-data">{{ order.claim_no ? order.claim_no : 'brak' }}</span>
              </div>
              <div class="column w-1/2">
                <span class="title">zlecający</span>
                <span v-if="canEverything || userAbilities.includes('contractor_list')" class="field-data"><router-link :to="{ name: 'contractor', params: { id: order.contractor.id } }">{{ order.contractor.company.shortname }}</router-link></span>
                <span v-else class="field-data">{{ order.contractor.company.shortname }}</span>
              </div>
              <div v-if="canEverything" class="column w-full mb-2">
                <span class="title">uwagi do szkody <span class="text-xs font-hairline">(tylko administratorzy)</span></span>
                <span v-if="order.comments" class="field-data">{{ order.comments }}</span>
                <span v-else class="field-data"><span class="text-gray-500 text-sm"><em>brak</em></span></span>
              </div>
              <div v-show="canEverything || userAbilities.includes('registrar')" class="column w-full border rounded-sm mx-3">
                <div class="flex justify-between">
                  <span class="title">opiekunowe ze strony TU</span>
                  <div ref="wrapper" class="relative">
                    <font-awesome-icon v-if="!showAddNewCcp" @click="showAddNewCcpHandler" icon="user-plus" class="mt-1 -mr-1 text-gray-500 hover:text-blue-700 cursor-pointer" title="przypisz opiekuna" />
                    <font-awesome-icon v-else @click="showAddNewCcpHandler" icon="times" class="mt-1 -mr-1 text-gray-500 hover:text-gray-700 cursor-pointer" title="anuluj" />
                    <div v-show="showAddNewCcp" class="absolute mt-6 top-0 right-0">
                      <ul class="py-2 border rounded-sm bg-yellow-100 opacity-75">
                        <li v-for="ccPerson in ccPeopleToSelect" @click="addOrderCcPerson(ccPerson.id)" :value="ccPerson.id" :key="ccPerson.id" class="whitespace-no-wrap px-3 hover:bg-blue-400 cursor-pointer">
                          {{ ccPerson.name }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="flex items-baseline">
                  <div class="w-full">
                    <span v-if="order.c_c_persons.length === 0" class="field-data mr-3 text-gray-500">nie przypisano opiekunów</span>
                    <div v-for="ccPerson in order.c_c_persons" :key="ccPerson.id">
                      <span class="field-data mr-3">
                        {{ ccPerson.person.first_name }} {{ ccPerson.person.surname }}
                        <a :href="`mailto:${ccPerson.person.email}`" class="mr-3 whitespace-no-wrap"><font-awesome-icon icon="at" class="text-gray-700" />{{ ccPerson.person.email }}</a>
                        <a v-if="ccPerson.person.phone" :href="`tel:${ccPerson.person.phone}`" class="whitespace-no-wrap"><font-awesome-icon icon="phone" class="text-gray-700" />{{ ccPerson.person.phone | phoneNo }}</a>
                        <font-awesome-icon @click="removeOrderCcPerson(ccPerson.id)" icon="times" size="sm" class="ml-2 mt-1 text-gray-500 hover:text-red-700 cursor-pointer float-right" title="odłącz opiekuna" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> <!-- end data section -->
            <div class="section-title">miejsce oględzin i kontakt</div>
            <hr>
            <div class="card-data-section">
              <div class="column w-full">
                <div class="flex justify-between">
                  <span class="title">kontakt</span>
                  <div v-if="order.subject">
                    <font-awesome-icon v-show="canEverything || userAbilities.includes('registrar')" @click="subjectEdit" icon="edit" class="text-gray-500 hover:text-blue-700 cursor-pointer" title="edytuj dane kontaktowe" />
                    <font-awesome-icon v-show="canEverything" @click="confirmAnonimize" icon="user-slash" class="ml-1 text-gray-500 hover:text-red-700 cursor-pointer" title="anonimizuj dane" />
                  </div>
                </div>
                <span v-if="!order.subject" class="text-red-700 font-semibold">dane zanonimizowane</span>
                <span v-else-if="order.subject.company" class="field-data">{{ order.subject.company.name }}</span>
                <span v-else class="field-data">{{ `${order.subject.person.first_name} ${order.subject.person.surname}` }}</span>
              </div>
              <div class="column w-1/2">
                <span class="title">telefon ze zgł.</span>
                <span class="field-data">
                  <a v-if="order.phone" :href="`tel:${order.phone}`">{{ order.phone | phoneNo }}</a>
                  <span v-else class="text-muted">nie podano</span>
                </span>
              </div>
              <div class="column w-1/2">
                <span class="title">email ze zgł</span>
                <span class="field-data">
                  <a v-if="order.email" :href="`mailto:${order.email}`">{{ order.email }}</a>
                  <span v-else class="text-muted">nie podano</span>
                </span>
              </div>
              <template v-if="personExtContactData || companyExtContactData">
                <div class="column w-1/2">
                  <span class="title">dodatkowy telefon</span>
                  <span v-if="personExtContactData" class="field-data">
                    <a v-if="order.subject.person.phone" :href="`tel:${order.subject.person.phone}`">{{ order.subject.person.phone | phoneNo }}</a>
                    <span v-else class="text-muted">nie podano</span>
                  </span>
                  <span v-else class="field-data">
                    <a v-if="order.subject.company.phone" :href="`tel:${order.subject.company.phone}`">{{ order.subject.company.phone | phoneNo }}</a>
                    <span v-else class="text-muted">nie podano</span>
                  </span>
                </div>
                <div class="column w-1/2">
                  <span class="title">dodatkowy email</span>
                  <span v-if="personExtContactData" class="field-data">
                    <a v-if="order.subject.person.email" :href="`mailto:${order.subject.person.email}`">{{ order.subject.person.email }}</a>
                    <span v-else class="text-muted">nie podano</span>
                  </span>
                  <span v-else class="field-data">
                    <a v-if="order.subject.company.email" :href="`mailto:${order.subject.company.email}`">{{ order.subject.company.email }}</a>
                    <span v-else class="text-muted">nie podano</span>
                  </span>
                </div>
              </template>
              <div class="column w-1/2">
                <span class="title">adres ze zgłoszenia</span>
                <span class="field-data">{{ order.street }} {{ order.house_apt_no }}; {{ order.postal_code }} {{ order.city }}</span>
              </div>
              <div class="column w-1/2">
                <span class="title">region</span>
                <span v-if="order.region" class="field-data">{{ order.region.name }}</span>
                <span v-else class="field-data">nie podano</span>
              </div>
            </div>

          </div> <!-- end card-content -->
        </div> <!-- end card -->

        <Notes
          v-show="canEverything || $store.state.user.abilities.includes('registrar') || roles.is_inspector || roles.is_valuator"
          title="Korespondencja wewnętrzna"
          :noteableId="order.id"
          noteableType="App\Order"
          categoryId="10"
          :notes="internalNotes"
          :roles="roles"
          :token="order.images_token"
          @added="noteAdded"
          @changed="noteChanged"
          @subfAttrChanged="noteSubfAttrChanged"
          @deleted="noteDeleted"
          @deletedMany="noteDeletedMany"
        />

        <OrderSalaries
          v-if="canEverything"
          :order="order"
          @incomeUpdated="incomeUpdated"
          @settlementsCheckedChanged="settlementsCheckedChanged"
        />

      </div> <!-- end column left -->

      <div class="container-column w-full md:w-1/2"> <!-- column right -->

        <OrderHistory
          :order="order"
          :roles="roles"
          @changed="updateOrder"
        />

        <div class="card">
          <div class="card-header">
            <div class="card-header-title">Przypisani pracownicy do zlecenia</div>
          </div>
          <div class="card-content">

            <OrderEmployees
              title="nadzór"
              task="1"
              :employees="order.supervisions"
              @added="employeeAdded"
              @deleted="detachEmployeeHandler"
            />

            <OrderEmployees
              v-show="canEverything || roles.is_inspector || $store.state.user.abilities.includes('registrar')"
              title="oględziny"
              task="10"
              :employees="order.inspectors"
              @added="employeeAdded"
              @deleted="detachEmployeeHandler"
            />

            <OrderEmployees
              v-show="canEverything || roles.is_valuator || $store.state.user.abilities.includes('registrar')"
              title="kosztorys"
              task="20"
              :employees="order.valuators"
              @added="employeeAdded"
              @deleted="detachEmployeeHandler"
            />

          </div>
        </div> <!-- end card -->

        <Notes
          v-show="canEverything || roles.is_inspector || roles.is_valuator || roles.is_contractor"
          title="Dokumentacja - oględziny"
          :noteableId="order.id"
          noteableType="App\Order"
          categoryId="20"
          :notes="inspectionNotes"
          :roles="roles"
          :token="order.images_token"
          @added="noteAdded"
          @changed="noteChanged"
          @subfAttrChanged="noteSubfAttrChanged"
          @deleted="noteDeleted"
          @deletedMany="noteDeletedMany"
        />

        <Notes
          v-show="canEverything || roles.is_valuator || roles.is_contractor"
          title="Dokumentacja - kosztorys"
          :noteableId="order.id"
          noteableType="App\Order"
          categoryId="30"
          :notes="valuationNotes"
          :roles="roles"
          :token="order.images_token"
          @added="noteAdded"
          @changed="noteChanged"
          @subfAttrChanged="noteSubfAttrChanged"
          @deleted="noteDeleted"
          @deletedMany="noteDeletedMany"
        />

        <Notes
          v-show="canEverything || roles.is_contractor || $store.state.user.abilities.includes('registrar')"
          title="Korespondencja z TU"
          :noteableId="order.id"
          noteableType="App\Order"
          categoryId="40"
          :notes="contractorNotes"
          :roles="roles"
          :token="order.images_token"
          @added="noteAdded"
          @changed="noteChanged"
          @subfAttrChanged="noteSubfAttrChanged"
          @deleted="noteDeleted"
          @deletedMany="noteDeletedMany"
        />

      </div> <!-- end column right -->
    </div> <!-- cards wrapper -->

    <OrderEdit
      v-if="showOrderEdit"
      @close="showOrderEdit = false"
      @changed="getOrder"
      :order="order"
      :roles="roles"
    />

    <PersonEdit
      v-if="showPersonEdit"
      @close="showPersonEdit = false"
      @changed="getOrder"
      :person="order.subject.person"
    />

    <CompanyEdit
      v-if="showCompanyEdit"
      @close="showCompanyEdit = false"
      @changed="getOrder"
      :company="order.subject.company"
    />

    <OrderZipInfo
      :activeModal="zipInfo"
      :zipUrl="zipUrl"
      :done="zipReady"
      @closeModal="closeOrderZipInfo"
    />

  </div>
</template>

<script>
import OrderService from '../services/OrderService';
// eslint-disable-next-line no-unused-vars
import NoteService from '../services/NoteService';
import { errorHandler } from '../mixins/errorHandler';
import { confirm } from '../mixins/confirm';
import Notes from '../components/Notes.vue';
import OrderEmployees from '../components/OrderEmployees.vue';
import OrderEdit from '../components/OrderEdit.vue';
import PersonEdit from '../components/PersonEdit.vue';
import CompanyEdit from '../components/CompanyEdit.vue';
import OrderHistory from '../components/OrderHistory.vue';
import OrderZipInfo from '../components/OrderZipInfo.vue';
import OrderSalaries from '../components/OrderSalaries.vue';

export default {
  name: 'OrderItem',
  components: {
    Notes,
    OrderEmployees,
    OrderEdit,
    PersonEdit,
    CompanyEdit,
    OrderHistory,
    OrderZipInfo,
    OrderSalaries,
  },
  mixins: [errorHandler, confirm],
  data() {
    return {
      order: {
        contractor: {},
      },
      roles: {},
      isBusy: true,
      zipInfo: false,
      zipUrl: '',
      zipReady: false,
      showOrderEdit: false,
      showPersonEdit: false,
      showCompanyEdit: false,
      showAddNewCcp: false,
      newCcpId: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    userAbilities() {
      return this.$store.state.user.abilities;
    },
    canEverything() {
      return this.userAbilities.includes('can_everything');
    },
    userIsRegistrar() {
      return this.userAbilities.includes('registrar');
    },
    userIsContractor() {
      return this.roles.is_contractor;
    },
    userIsInspector() {
      return this.roles.is_inspector;
    },
    userIsValuator() {
      return this.roles.is_valuator;
    },
    personExtContactData() {
      return !!(this.order.subject && this.order.subject.person && (this.order.subject.person.phone || this.order.subject.person.email));
    },
    companyExtContactData() {
      return !!(this.order.subject && this.order.subject.company && (this.order.subject.company.phone || this.order.subject.company.email));
    },
    contractorContactPeople() {
      return this.order.contractor.contractor_contact_people;
    },
    existsOrderCcPeopleIdsArr() {
      return this.order.c_c_persons.map(obj => obj.id);
    },
    ccPeopleToSelect() {
      const notSelected = this.contractorContactPeople.filter(obj => !this.existsOrderCcPeopleIdsArr.includes(obj.id));
      return notSelected.map(obj => ({ id: obj.id, name: `${obj.person.first_name} ${obj.person.surname}` }));
    },
    internalNotes() {
      return this.order.notes.filter(obj => Number(obj.category_id) === 10);
    },
    inspectionNotes() {
      return this.order.notes.filter(obj => Number(obj.category_id) === 20);
    },
    valuationNotes() {
      return this.order.notes.filter(obj => Number(obj.category_id) === 30);
    },
    contractorNotes() {
      return this.order.notes.filter(obj => Number(obj.category_id) === 40);
    },
  },
  mounted() {
    this.$store.dispatch('notes/getNoteSubfolderTypeList');
    this.getOrder();
  },
  destroyed() {
    document.removeEventListener('click', this.addCcpClick);
  },
  methods: {
    getOrder() {
      this.isBusy = true;
      OrderService.getOrder(this.id)
        .then(response => {
          this.order = response.data.result;
          this.roles = response.data.roles;
          this.isBusy = false;
        })
        .catch(error => {
          this.resolveError(error);
          this.isBusy = false;
        });
    },
    subjectEdit() {
      if (this.order.subject) {
        if (Number(this.order.subject.is_company) === 1) {
          this.showCompanyEdit = true;
        } else {
          this.showPersonEdit = true;
        }
      } else {
        this.$store.dispatch('notifications/add', {
          type: 'warning',
          message: 'Dane zostały wcześniej zanonimizowane...',
        });
      }
    },
    confirmDeleteOrder() {
      this.confirm(() => this.deleteOrder(), 'Czy na pewno usunąć zlecenie? Dane poszkodowanego oraz wszystkie załączniki zostaną usunięte nieodwracalnie!');
    },
    deleteOrder() {
      OrderService.deleteOrder(this.id)
        .then(() => {
          this.$store.dispatch('notifications/add', {
            type: 'success',
            message: 'Zlecenie zostało usunięte...',
          });
          this.$router.push({ name: 'orders' });
        })
        .catch(error => {
          this.resolveError(error);
        });
    },
    confirmAnonimize() {
      this.confirm(() => this.anonimizeSubject(), 'Czy na pewno zanonimizować dane poszkodowanego? Zmiana jest nieodwracalna.');
    },
    anonimizeSubject() {
      OrderService.anionimizeSubject(this.id)
        .then(() => {
          this.getOrder();
        })
        .catch(error => {
          this.resolveError(error);
        });
    },
    showAddNewCcpHandler() {
      if (this.showAddNewCcp) {
        this.showAddNewCcp = false;
        document.removeEventListener('click', this.addCcpClick);
      } else {
        this.showAddNewCcp = true;
        document.addEventListener('click', this.addCcpClick);
      }
    },
    addCcpClick(e) {
      const { wrapper } = this.$refs;
      const { target } = e;
      if (!wrapper.contains(target)) {
        this.showAddNewCcp = false;
        document.removeEventListener('click', this.addCcpClick);
      }
    },
    addOrderCcPerson(id) {
      const ccPersons = this.existsOrderCcPeopleIdsArr.concat(id);
      this.showAddNewCcpHandler();
      this.updateOrder({ c_c_persons: ccPersons });
    },
    removeOrderCcPerson(id) {
      const ccPersons = this.existsOrderCcPeopleIdsArr.filter(el => el !== id);
      this.updateOrder({ c_c_persons: ccPersons });
    },
    employeeAdded(payload) {
      this.attachEmployee(payload);
    },
    attachEmployee(form) {
      if (!form.id) {
        return false;
      }
      OrderService.attachEmployee(this.order.id, form)
        .then(() => {
          this.getOrder();
        })
        .catch(error => {
          this.resolveError(error);
        });
      return true;
    },
    detachEmployeeHandler(form) {
      this.confirm(() => this.detachEmployee(form), 'Potwierdź odłączenie pracownika od zlecenia');
    },
    detachEmployee(form) {
      OrderService.detachEmployee(this.order.id, form.id, form.order_task_id)
        .then(() => {
          this.getOrder();
        })
        .catch(error => {
          console.log(this.resolveError(error));
        });
    },
    updateOrder(fields) {
      OrderService.updateOrder(this.order.id, fields)
        .then(() => {
          this.getOrder();
        })
        .catch(error => {
          this.resolveError(error);
        });
    },
    noteAdded(note) {
      this.order.notes.unshift(note);
    },
    noteChanged(note) {
      const oldNote = this.order.notes.find(obj => Number(obj.id) === Number(note.id));
      Object.assign(oldNote, note);
    },
    noteSubfAttrChanged(newVal) {
      const notesToChange = this.order.notes.filter(obj => Number(obj.note_subfolder_id) === Number(newVal.id));
      notesToChange.forEach(obj => {
        // eslint-disable-next-line no-param-reassign
        obj.note_subfolder.note_subfolder_attribute = newVal.note_subfolder_attribute;
      });
    },
    noteDeleted(id) {
      this.order.notes = this.order.notes.filter(obj => Number(obj.id) !== Number(id));
    },
    noteDeletedMany(ids) {
      const idsDeleted = ids.map(el => Number(el));
      this.order.notes = this.order.notes.filter(obj => !idsDeleted.includes(obj.id));
    },
    incomeUpdated(newIncome) {
      this.order.income = newIncome;
    },
    settlementsCheckedChanged() {
      this.updateOrder({ settlements_checked: 0 });
    },
    downloadCompleteOrder() {
      this.zipInfo = true;
      this.zipUrl = '';
      NoteService.downloadCompleteOrder(this.order.id)
        .then(response => {
          this.zipUrl = response.data.url;
          this.zipReady = true;
          // const newWindow = window.open(null, 'downloadZIP');
          // newWindow.location.href = response.data.url;
        })
        .catch(error => {
          this.resolveError(error);
        });
    },
    setAsDownloaded() {
      OrderService.setAsDownloaded(this.order.id)
        .then(() => {
          this.getOrder();
        })
        .catch(error => {
          this.resolveError(error);
        });
    },
    closeOrderZipInfo() {
      this.zipInfo = false;
      this.zipUrl = '';
      this.zipReady = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .main-container {
    @apply flex;
    @apply flex-wrap;
    @apply -mx-3;
  }
  .container-column {
    @apply px-3;
  }
  .card {
    @apply my-2;
  }
  .card-header {
     @apply flex;
     @apply justify-between;
     @apply px-3;
     @apply py-1;
     @apply border;
     @apply border-b-0;
     @apply border-gray-400;
     @apply bg-gray-100;
     @apply rounded-t-sm;
     &.dark {
       @apply bg-gray-900;
       @apply text-gray-200;
       @apply border-gray-700;
     }
  }
  .card-header-title {
    @apply font-semibold;
  }
  .card-content {
    @apply px-3;
    @apply pt-1;
    @apply pb-3;
    @apply border;
    @apply border-gray-400;
    &.dark {
      @apply bg-gray-800;
      @apply text-gray-200;
      @apply border-gray-700;
    }
  }
  .card-data-section {
    @apply flex;
    @apply flex-wrap;
    @apply -mx-3;
    .column {
      @apply px-3;
    }
    .title {
      @apply block;
      @apply font-semibold;
    }
    .title-inline {
      @apply inline-block;
      @apply font-semibold;
    }
    .field-data {
      @apply inline-block;
      @apply w-full;
      @apply px-2;
      @apply mb-1;
      @apply border;
      @apply bg-gray-200;
      @apply rounded-sm;
      &.dark {
        @apply text-gray-700;
      }
    }
    .field-data-inline {
      @apply inline-block;
      @apply px-2;
      @apply mb-1;
      @apply border;
      @apply bg-gray-200;
      @apply rounded-sm;
      &.dark {
        @apply bg-gray-700;
        @apply border-gray-800;
        @apply text-gray-200;
      }
      &.text-muted-dark {
        @apply text-gray-500;
      }
    }
  }
  .section-title {
    @apply mt-1;
    @apply text-gray-600;
    @apply text-sm;
    @apply font-semibold;
    @apply uppercase;
  }
  .text-muted {
    @apply text-gray-500;
  }
</style>
