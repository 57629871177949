<template>
  <div>
    <div class="flex justify-between">
      <div class="section-title">{{ title }}</div>
      <div v-show="allowModify" @click="showEmployeeAdd = true" class="green-but-icon" title="przypisz pracownika">
        <font-awesome-icon icon="plus" size="xs" />
        <font-awesome-icon icon="user-tie" />
      </div>
    </div>
    <div>
      <div v-if="employees.length === 0" class="employee-box">
        <span class="empty-box">nie przypisano pracowników</span>
      </div>
      <div v-else class="employee-box">
        <div v-for="empl in employees" :key="empl.id">
          <span class="inline-block w-1/3">
            {{ empl.person.first_name }} {{ empl.person.surname }}
          </span>
          <span class="inline-block w-3/12">
            <font-awesome-icon icon="phone" /> <a :href="`tel:${empl.person.phone}`">{{ empl.person.phone | phoneNo }}</a>
          </span>
          <span class="inline-block w-1/3">
            <font-awesome-icon icon="at" /> <a :href="`mailto:${empl.person.email}`">{{ empl.person.email }}</a>
          </span>
          <span v-show="allowModify" class="inline-block w-1/12">
            <font-awesome-icon @click="$emit('deleted', { id: empl.id, order_task_id: task })" icon="trash-alt" size="sm" class="float-right red-but-icon" title="usuń przypisanie" />
          </span>
        </div>
      </div>
    </div>

    <OrderEmployeeAdd v-if="showEmployeeAdd && allowModify" @added="added" @close="showEmployeeAdd = false" />

  </div>
</template>

<script>
import OrderEmployeeAdd from './OrderEmployeeAdd.vue';

export default {
  name: 'OrderEmployees',
  components: { OrderEmployeeAdd },
  props: {
    title: {
      type: String,
      required: true,
    },
    task: {
      type: String,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showEmployeeAdd: false,
    };
  },
  computed: {
    allowModify() {
      return this.$store.state.user.abilities.includes('can_everything') || this.$store.state.user.abilities.includes('registrar');
    },
  },
  methods: {
    added(payload) {
      this.$emit('added', { id: payload.id, order_task_id: this.task });
    },
  },
};
</script>

<style lang="scss" scoped>
  .section-title {
    @apply mt-1;
    @apply text-gray-600;
    @apply text-sm;
    @apply font-semibold;
    @apply uppercase;
  }
  .green-but-icon {
    @apply text-gray-600;
    @apply cursor-pointer;
    &:hover {
      @apply text-green-700;
    }
  }
  .red-but-icon {
    @apply text-gray-500;
    @apply cursor-pointer;
    &:hover {
      @apply text-red-700;
    }
  }
  .employee-box {
    @apply mt-0;
    @apply mb-3;
    @apply px-3;
    @apply py-1;
    @apply border;
    @apply border-gray-400;
    @apply rounded;
  }
  .empty-box {
    @apply inline-block;
    @apply text-gray-500;
    @apply  py-2;
  }
</style>
