<template>
  <BaseModal
    :activeModal="show"
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="przypisz"
    @ok="save"
    :close="false"
    :bgClose="true"
  >
    <template #header>
      Przypisywanie pracownika do zlecenia
    </template>
    <template #default>
      <div v-if="isBusy" class="w-full p-4 h-full text-center">
        <font-awesome-icon class="text-6xl" icon="spinner" pulse/>
      </div>
      <div v-else class="flex flex-wrap">
        <div class="mb-4 w-1/2 px-1">
          <label>nazwisko (fragment)</label>
          <Autocomplete
            resource="employees-by/surname"
            v-model="surname"
            @selected="setEmployee"
          />
        </div>
        <div class="mb-4 w-1/2 px-1">
          <label>region (fragment)</label>
          <Autocomplete
            resource="employees-by/region"
            v-model="region"
            @selected="setEmployee"
          />
        </div>
        <div class="w-full bg-gray-200 px-3">
          <p v-if="selectedEmployee.id">
            {{ selectedEmployee.first_name }} {{ selectedEmployee.surname }}
            <a v-show="selectedEmployee.email" :href="`mailto:${selectedEmployee.email}`" class="mr-2"><font-awesome-icon icon="at" />{{ selectedEmployee.email }}</a>
            <a v-show="selectedEmployee.phone" :href="`tel:${selectedEmployee.phone}`"><font-awesome-icon icon="phone" />{{ selectedEmployee.phone |phoneNo }}</a>
            <span v-show="selectedEmployee.city || selectedEmployee.region"><br>{{ selectedEmployee.city }} {{ selectedEmployee.region }}</span>
          </p>
          <p v-else class="text-gray-500">zacznij pisac nazwisko lub region, aby wybrać z podpowiedzi...</p>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapState } from 'vuex';
import Autocomplete from './Autocomplete.vue';

export default {
  name: 'OrderEmployeeAdd',
  components: { Autocomplete },
  data() {
    return {
      surname: '',
      region: '',
      selectedEmployee: {},
      errors: {},
      show: false,
      isBusy: false,
    };
  },
  computed: {
    ...mapState('regions', ['regions']),
  },
  mounted() {
    if (this.regions.length === 0) {
      this.$store.dispatch('regions/get');
    }
    this.show = true;
  },
  methods: {
    save() {
      if (this.selectedEmployee.id) {
        this.isBusy = true;
        this.$emit('added', this.selectedEmployee);
      }
    },
    setEmployee(payload) {
      this.selectedEmployee = payload;
      this.region = '';
      this.surname = '';
    },
  },
};
</script>
