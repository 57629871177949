<template>
  <div class="card">
    <div class="card-header dark">
      <span class="card-header-title">historia zlecenia</span>
      <span v-show="canEverything" class="card-header-title text-yellow-600 cursor-pointer" title="Historia zlecenia">
        <font-awesome-icon @click="showHistory = !showHistory" icon="history" />
      </span>
    </div> <!-- enc card header -->
    <div class="card-content dark">
      <div v-show="allowEdit" class="w-full flex justify-end"> <!-- ikonki -->
        <font-awesome-icon v-if="!showDateEdit && (order.valuation_date !== '' || order.close_date !== '')" @click="showDateEdit = true" class="text-gray-500 hover:text-blue-600 cursor-pointer" icon="edit" title="edytuj" />
        <font-awesome-icon v-else-if="order.valuation_date === '' && order.close_date === ''" class="text-gray-700" icon="edit" title="edycja zablokowana" />
        <div v-else>
          <button
            @click="saveHandler"
            class="font-semibold text-sm border border-red-500 text-red-500 hover:bg-red-500 hover:text-white px-1 rounded-sm"
            :disabled="form.valuation_date === order.valuation_date && form.close_date === order.close_date"
          >
            <font-awesome-icon icon="save" /> zapisz
          </button>
          <button @click="showDateEdit = false" class="ml-1 font-semibold text-sm border border-yellow-500 text-yellow-500 hover:bg-yellow-500 hover:text-gray-700 px-1 rounded-sm">
            <font-awesome-icon icon="times" /> anuluj
          </button>
        </div>
      </div> <!-- end ikonki -->
      <div class="card-data-section">
        <div class="column w-1/2">
          <span class="title-inline w-32 mr-2">data zlecenia:</span>
          <span class="field-data-inline dark w-32">{{ order.order_date }}</span>
        </div>
        <div class="column w-1/2">
          <span class="title-inline w-32 mr-2">data oględzin:</span>
          <span class="field-data-inline dark w-32" :class="{'text-muted-dark font-hairline': !order.inspection_date}">{{ order.inspection_date ? order.inspection_date : 'brak' }}</span>
        </div>
        <div class="column w-1/2">
          <span class="title-inline w-32 mr-2">data kosztorysu:</span>
          <input v-if="showDateEdit && order.valuation_date !== ''" v-model="form.valuation_date" class="w-40 px-2 py-0 text-gray-700" type="date" :disabled="!canEverything && !roles.is_valuator">
          <span v-else class="field-data-inline dark w-32" :class="{'text-muted-dark font-hairline': !order.valuation_date}">{{ order.valuation_date ? order.valuation_date : 'brak' }}</span>
        </div>
        <div class="column w-1/2">
          <span class="title-inline w-32 mr-2">data zamknięcia:</span>
          <input v-if="showDateEdit && order.close_date !== ''" v-model="form.close_date" class="w-40 px-2 py-0 text-gray-700" type="date" :disabled="!canEverything">
          <span v-else class="field-data-inline dark w-32" :class="{'text-muted-dark font-hairline': !order.close_date}">{{ order.close_date ? order.close_date : 'brak' }}</span>
        </div>
      </div>
      <Transition name="fade">
        <div v-show="showHistory">
          <div class="flex flex-wrap mt-3 py-1 px-2 border border-gray-800 rounded-t bg-gray-700 w-full text-sm">
            <div class="w-1/2 xl:w-1/4">czas zmiany</div>
            <div class="w-1/2 xl:w-1/12">przedmiot</div>
            <div class="flex justify-center w-1/2 xl:w-1/4">z...</div>
            <div class="flex justify-center w-1/2 xl:w-1/4">na...</div>
            <div class="w-1/2 xl:w-1/12">użytk.</div>
            <div class="flex justify-end w-1/2 xl:w-1/12">opis</div>
          </div>
          <div v-show="history.length === 0" class="px-3 py-1 text-sm text-gray-500">brak rekordów historii zlecenia</div>
          <div v-for="(row, index) in history" class="flex flex-wrap py-1 px-2 border w-full text-xs" :class="{'border-gray-900 bg-gray-800': index % 2 === 0, 'border-gray-800 bg-gray-900': index % 2 === 1}" :key="row.id">
            <div class="w-1/2 xl:w-1/4">{{ row.created_at }}</div>
            <div class="w-1/2 xl:w-1/12">{{ row.subject }}</div>
            <div class="w-1/2 xl:w-1/4">{{ row.old_value }}</div>
            <div class="w-1/2 xl:w-1/4">{{ row.new_value }}</div>
            <div class="w-1/2 xl:w-1/12">{{ row.user.name }}</div>
            <div class="flex items-center justify-end w-1/2 xl:w-1/12"><font-awesome-icon icon="question-circle" class="text-blue-300 float-right" :title="row.description" /></div>
          </div>
        </div>
      </Transition>
    </div>
  </div> <!-- end card -->
</template>

<script>
import moment from 'moment';
import OrderService from '../services/OrderService';
import { errorHandler } from '../mixins/errorHandler';
import { confirm } from '../mixins/confirm';

export default {
  name: 'OrderHistory',
  mixins: [confirm, errorHandler],
  props: {
    order: {
      type: Object,
      required: true,
    },
    roles: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDateEdit: false,
      showHistory: false,
      form: {
        valuation_date: this.order.valuation_date,
        close_date: this.order.close_date,
      },
      history: [],
      isBusy: false,
    };
  },
  computed: {
    canEverything() {
      return this.$store.state.user.abilities.includes('can_everything');
    },
    allowEdit() {
      return this.canEverything || this.roles.is_valuator;
    },
  },
  mounted() {
    if (this.canEverything) {
      this.getHistory();
    }
  },
  methods: {
    getHistory() {
      this.isBusy = true;
      OrderService.getLogs(this.order.id)
        .then(response => {
          this.history = response.data.result;
          this.history.forEach(obj => {
            // eslint-disable-next-line no-param-reassign
            obj.created_at = moment(obj.created_at).format('YYYY-MM-DD HH:mm:ss');
          });
          this.isBusy = false;
        })
        .catch(error => {
          this.resolveError(error);
          this.isBusy = false;
        });
    },
    saveHandler() {
      const message = 'Ręczna zmiana dat, które zostały wygenerowane automatycznie przez zmianę statusu jest niewskazana. Pamiętaj, aby po tej czynności zweryfikować prawidłowy status zlecenia. Czy kontynuować?';
      this.confirm(() => this.save(), message);
    },
    save() {
      const fields = {};
      if (this.form.valuation_date !== this.order.valuation_date) {
        fields.valuation_date = this.form.valuation_date;
      }
      if (this.form.close_date !== this.order.close_date) {
        fields.close_date = this.form.close_date;
      }
      this.$emit('changed', fields);
      this.showDateEdit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .card {
    @apply my-2;
  }
  .card-header {
     @apply flex;
     @apply justify-between;
     @apply px-3;
     @apply py-1;
     @apply border;
     @apply border-b-0;
     @apply border-gray-400;
     @apply bg-gray-100;
     @apply rounded-t-sm;
     &.dark {
       @apply bg-gray-900;
       @apply text-gray-200;
       @apply border-gray-700;
     }
  }
  .card-header-title {
    @apply font-semibold;
  }
  .card-content {
    @apply px-3;
    @apply pt-1;
    @apply pb-3;
    @apply border;
    @apply border-gray-400;
    &.dark {
      @apply bg-gray-800;
      @apply text-gray-200;
      @apply border-gray-700;
    }
  }
  .card-data-section {
    @apply flex;
    @apply flex-wrap;
    @apply -mx-3;
    .column {
      @apply px-3;
    }
    .title {
      @apply block;
      @apply font-semibold;
    }
    .title-inline {
      @apply inline-block;
      @apply font-semibold;
    }
    .field-data {
      @apply inline-block;
      @apply w-full;
      @apply px-2;
      @apply mb-1;
      @apply border;
      @apply bg-gray-200;
      @apply rounded-sm;
      &.dark {
        @apply text-gray-700;
      }
    }
    .field-data-inline {
      @apply inline-block;
      @apply px-2;
      @apply mb-1;
      @apply border;
      @apply bg-gray-200;
      @apply rounded-sm;
      &.dark {
        @apply bg-gray-700;
        @apply border-gray-800;
        @apply text-gray-200;
      }
      &.text-muted-dark {
        @apply text-gray-500;
      }
    }
  }
</style>
