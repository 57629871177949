<template>
  <BaseModal
    :activeModal="show"
    @closeModal="$emit('close')"
    :cancel="true"
    :ok="true"
    okLabel="zapisz"
    @ok="saveHandler"
    :close="false"
    :bgClose="false"
    size="md"
  >
    <template #header>
      {{modalTitle}}
    </template>
    <template #default>
      <div v-if="isBusy" class="w-full p-4 h-full text-center">
        <font-awesome-icon class="text-6xl" icon="spinner" pulse/>
      </div>
      <div v-else class="flex flex-wrap">
        <div class="mb-4 w-full md:w-2/3 px-1">
          <label>typ wynagrodzenia</label>
          <select v-model="form.salary_type_id" @change="form.description = '';delete errors.salary_type_id" :class="{ 'border-red-300': errors.salary_type_id !== undefined }">
            <option value="" disabled hidden>Wybierz</option>
            <option v-for="type in types" :key="type.id" :value="type.id">{{ type.name }}</option>
          </select>
          <p v-if="errors.salary_type_id !== undefined" class="text-red-500 text-sm">{{ errors.salary_type_id }}</p>
        </div>
        <div class="mb-4 w-full md:w-1/3 px-1">
          <label>kwota</label>
          <input @input="delete errors.amount" :class="{ 'border-red-300': errors.amount !== undefined }" v-model="form.amount" type="text">
          <p v-if="errors.amount !== undefined" class="text-red-500 text-sm">{{ errors.amount }}</p>
        </div>
        <div v-if="form.salary_type_id === 20 || form.salary_type_id === '20'" class="mb-4 w-full px-1">
          <label>opis <em class="text-xs font-hairline">(opcjonalnie)</em></label>
          <input :class="{ 'border-red-300': errors.description !== undefined }" v-model="form.description" type="text">
          <p v-if="errors.description !== undefined" class="text-red-500 text-sm">{{ errors.description }}</p>
        </div>
        <div class="mb-4 w-1/3 px-1 text-left">
          <label class="block">
            <input v-model="isPaid" class="w-auto mr-2 mb-12" @change="setPaymentDate" true-value="1" false-value="0" type="checkbox">
            <span>rozliczone</span>
          </label>
        </div>
        <div class="mb-4 w-2/3 px-1">
          <label>data rozliczenia</label>
          <input :class="{ 'border-red-300': errors.payment_date !== undefined }" v-model="form.payment_date" type="date" :disabled="isPaid === '0'">
          <p v-if="errors.payment_date !== undefined" class="text-red-500 text-sm">{{ errors.payment_date }}</p>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import { errorHandler } from '../mixins/errorHandler';
import { confirm } from '../mixins/confirm';
import SalaryService from '../services/SalaryService';

export default {
  name: 'OrderSalaryAddEdit',
  mixins: [errorHandler, confirm],
  props: {
    salary: {
      type: Object,
      default() {
        return {};
      },
    },
    order: {
      type: Object,
      required: true,
    },
    employeeId: {
      default: '',
    },
  },
  data() {
    return {
      show: false,
      isBusy: false,
      mode: 'add',
      form: {
        order_id: this.order.id,
        employee_id: this.employeeId,
        salary_type_id: '',
        amount: '',
        description: '',
        payment_date: '',
      },
      isPaid: '0',
      errors: {},
    };
  },
  computed: {
    ...mapState('salaries', ['types']),
    modalTitle() {
      return this.mode === 'add' ? 'Dodawanie wynagrodzenia' : 'Edycja wynagrodzenia';
    },
  },
  mounted() {
    if (this.types.length === 0) {
      this.$store.dispatch('salaries/getTypes');
    }
    if (this.salary.id !== undefined) {
      this.mode = 'edit';
      this.form.order_id = this.salary.order_id;
      this.form.employee_id = this.salary.employee_id;
      this.form.salary_type_id = this.salary.salary_type_id;
      this.form.amount = this.salary.amount.replace('.', ',');
      this.form.description = this.salary.description;
      this.form.payment_date = this.salary.payment_date;
      this.isPaid = this.salary.payment_date ? '1' : '0';
    } else {
      this.form.salary_type_id = 10;
    }
    this.show = true;
  },
  methods: {
    setPaymentDate() {
      if (this.isPaid === 1 || this.isPaid === '1') {
        if (this.salary.id !== undefined && this.salary.payment_date) {
          this.form.payment_date = this.salary.payment_date;
        } else {
          this.form.payment_date = moment().format('YYYY-MM-DD');
        }
      } else {
        this.form.payment_date = '';
      }
    },
    saveHandler() {
      let settlementsCheckedChange = false;
      if (this.order.settlements_checked === 1 || this.order.settlements_checked === '1') {
        if ((this.mode === 'edit' && Number(this.salary.amount) !== Number(this.form.amount.replace(',', '.'))) || this.mode === 'add') {
          settlementsCheckedChange = true;
          this.confirm(() => this.save(settlementsCheckedChange), 'Rozliczenia posiadają status "sprawdzone". Zmiana spowoduje zmianę statusu rozliczeń na "do sprawdzenia".');
        } else {
          this.save(settlementsCheckedChange);
        }
      } else {
        this.save(settlementsCheckedChange);
      }
    },
    save(settlementsCheckedChange) {
      this.isBusy = true;
      if (this.isPaid === '1' && this.form.payment_date === '') {
        this.errors.payment_date = 'Pole wymagane, jeśli wyn. rozliczone';
        this.isBusy = false;
        return false;
      }
      const amountCache = this.form.amount;
      this.form.amount = this.form.amount.replace(',', '.');
      if (this.mode === 'add') {
        SalaryService.add(this.form)
          .then(response => {
            this.$store.dispatch('notifications/add', {
              type: 'success',
              message: 'Wynagrodzenie dodane',
            });
            this.isBusy = false;
            this.show = false;
            if (settlementsCheckedChange) {
              this.$emit('settlementsCheckedChanged');
            }
            this.$emit('added', response.data.result);
          })
          .catch(error => {
            this.isBusy = false;
            this.form.amount = amountCache;
            this.errors = this.resolveError(error);
          });
      } else {
        SalaryService.update(this.salary.id, this.form)
          .then(response => {
            this.$store.dispatch('notifications/add', {
              type: 'success',
              message: 'Wynagrodzenie zmodyfikowane',
            });
            this.isBusy = false;
            this.show = false;
            if (settlementsCheckedChange) {
              this.$emit('settlementsCheckedChanged');
            }
            this.$emit('updated', response.data.result);
          })
          .catch(error => {
            this.isBusy = false;
            this.form.amount = amountCache;
            this.errors = this.resolveError(error);
          });
      }
      return true;
    },
  },
};
</script>
